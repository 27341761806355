import React, { FunctionComponent } from 'react';
import {
    Col, Row, Container, Image, Button
} from 'react-bootstrap';
import DramaticHeader from './dramaticHeader';
import HomeSubheader from './homeSubheader';
import IsNotAuthenticated from '../../components/isNotAuthenticated';
import IsAuthenticated from '../../components/isAuthenticated';
import LoginButton from '../../components/loginButton';
import MainBanner from '../../assets/topbanner5.png';
import PlanetariumBanner from '../../assets/planetariumbanner.png';
import RealEstateBanner from '../../assets/mvreabanner4.png';
import FTLBanner from '../../assets/mvftlbanner2.png';
import PlanetariumDoor from '../../assets/planetariumdoor.png';
import RealEstateDoor from '../../assets/mvreadoor.png';
import FTLDoor from '../../assets/mvpedoor.png';
import People from '../../assets/topbannerpeople.png';
import OculusLogo from '../../assets/oculus-logo-transparent-white.png';
import { WithMultiverseApiProps } from '../../hoc/multiverseApiProvider';
import withMultiverseApi from '../../hoc/multiverseApiProvider/withMultiverseApi';


type HomeProps = {
} & WithMultiverseApiProps;

type HomeState = {

};

class Home extends React.Component<HomeProps, HomeState> {
    constructor(props: HomeProps) {
        super(props);
        this.state = {
        };
    }
  //on click gets deep link, sets up tab and starts the polling process to detect merge completion
  onClick = async ():Promise<void> => {
    const { multiverse: { requestOculusLaunch } } = this.props;
    await requestOculusLaunch({
        domain: "",
        location: "",
        title: "Launch Multiverse"
    });
  };
    render() {
        return (
            <Container fluid className="banner-container">

                <IsNotAuthenticated>
                    <Row className="banner-row" style={{height:"150px"}}>
                        <div className="header-right" style={{ position: "absolute", top: "70px", left:"20px", right:"20px" }}>
                            <b>Important Notice! Facebook login is no longer supported - <a href="https://www.multiverseupdates.com/updates/updates-to-login-system-at-multiverseonlineio?rq=login" target="_blank">click here to learn how to migrate your account.</a></b>
                        </div>
                    </Row>
                    <Row></Row>
                </IsNotAuthenticated>
                <Row className="banner-row top-banner" style={{ backgroundImage: `url(${MainBanner})`, position: "relative" }}>
                    <div style={{ position: "absolute", top: "70px", left: 0, right: 0, bottom: 0 }}>
                        <Image src={People} />
                    </div>
                </Row>

                <Row className="launch-row">
                    <Col>
                        <Button variant="primary" className="launch-button" onClick={this.onClick}>
                            <Image src={OculusLogo} />
                            <p>Launch In VR</p>
                        </Button>
                    </Col>
                </Row>

                <Row className="banner-row" style={{}}>
                    <Col>
                        <Container>
                            <Row>
                                <Col className="banner-row-textbox">

                                    <h2>Social VR</h2>
                                    <p>Multiverse is the most versatile social and collaborative app for VR. Free to download on your VR app store, you can explore our public domains or host your own content in your own private domain. To learn more visit <a href="https://www.multiverseupdates.com/">Multiverse Updates</a>.</p>
                                </Col>
                                <Col className="banner-row-imagebox d-none d-md-block">
                                    <Image src={PlanetariumDoor} className="banner-square-image" />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>


                <Row className="banner-row" style={{ backgroundImage: `url(${PlanetariumBanner})` }}>
                    <Col>
                        <Container>
                            <Row>
                                <Col className="banner-row-imagebox d-none d-md-block">

                                </Col>
                                <Col className="banner-row-textbox">

                                    <h2>The Planetarium</h2>
                                    <p>Explore the solar system in this crazy virtual reality planetarium that is our first free public domain. Experience awesome events nearly every day, and in between events, shoot rockets, solve puzzles, and watch the ISS orbit Earth live.</p>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>

                <Row className="banner-row" style={{}}>
                    <Col>
                        <Container>
                            <Row>
                                <Col className="banner-row-textbox">

                                    <h2>Sales Galleries</h2>
                                    <p>For businesses that want to engage with clients on a digital sales process, nothing beats being able to showcase your project online in 3D with high quality visuals. Walk around the property with your client in a way that no-one has been able to when selling off-plan before.</p>
                                </Col>
                                <Col className="banner-row-imagebox d-none d-md-block">
                                    <Image src={RealEstateDoor} className="banner-square-image" />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>

                <Row className="banner-row" style={{ backgroundImage: `url(${RealEstateBanner})` }}>
                    <Col>
                        <Container>
                            <Row>
                                <Col className="banner-row-imagebox d-none d-md-block">

                                </Col>
                                <Col className="banner-row-textbox">

                                    <h2>Multiverse Real Estate and Architecture</h2>
                                    <p>With a corporate sales gallery linked to your high quality properties & projects, this package allows you to collaborate and present to clients, colleagues, investors and partners all from one beautiful location.</p>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>

                <Row className="banner-row" style={{}}>
                    <Col>
                        <Container>
                            <Row>
                                <Col className="banner-row-textbox">

                                    <h2>Virtual Meetings</h2>
                                    <p>With a sharp rise in virtual meetings, nothing hones people’s attention and focus like being immersed in an executive suite. Present to your clients, hold board meetings & meet with your team online in this beautifully customised office suite.</p>
                                </Col>
                                <Col className="banner-row-imagebox d-none d-md-block">
                                    <Image src={FTLDoor} className="banner-square-image" />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>

                <Row className="banner-row" style={{ backgroundImage: `url(${FTLBanner})` }}>
                    <Col>
                        <Container>
                            <Row>
                                <Col className="banner-row-imagebox d-none d-md-block">

                                </Col>
                                <Col className="banner-row-textbox">

                                    <h2>Multiverse Productivity</h2>
                                    <p>With the ability to link your device to your cloud content repositories, you can access files and videos to bring into VR and showcase. No matter where you are in the world, you can meet up with and collaborate with anyone else on the content that matters to you.</p>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>

            </Container>
        )
    }
};
export default withMultiverseApi(Home);

/*
        <DramaticHeader>Welcome!&nbsp;.</DramaticHeader>

        <HomeSubheader>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          Etiam lacinia sem metus. Nunc eu dictum libero,
          a tincidunt felis. Fusce gravida, arcu ut scelerisque dignissim,
          libero risus molestie enim, fringilla facilisis eros ante ut ligula.
        </HomeSubheader>
        */

/*

      <div className="d-none d-md-block pt-3">
      <HomeSubheader>
Welcome To Multiverse!
        </HomeSubheader>

      </div>

    <IsNotAuthenticated>
    </IsNotAuthenticated>

<IsAuthenticated>
<HomeSubheader>

Here you can manage your profile and find locations to explore on your VR headset!
        </HomeSubheader>

</IsAuthenticated>

        <IsNotAuthenticated>
            <Row className="pt-3">
                <Col xs="12">
                <p>Multiverse is a VR app for the Oculus Quest and Oculus Rift. If you've not already played, you can get started <a href="https://www.oculus.com/experiences/quest/1976071352472244">here</a>.</p>
            <p>Here at multiverseonline.io you can manage your Multiverse account, connect other accounts to Multiverse and much much more!</p>
        <p className="mb-0">To get started, sign in or create an account below</p>
                </Col>
            </Row>

            <div className="">
            <Row className="pt-2">
              <Col xs="12" className="">
                  <div style={{margin:"auto", textAlign:"center"}} className="d-none d-md-block">
                <LoginButton connection="facebook" />
                <LoginButton connection="google" />
                <LoginButton/>
                </div>
                <div style={{margin:"auto", textAlign:"center"}} className="d-block d-md-none">
                <LoginButton connection="facebook" small={true} />
                <LoginButton connection="google" small={true} />
                <LoginButton small={true} />
                </div>
              </Col>
            </Row>
            </div>


        </IsNotAuthenticated>

        */