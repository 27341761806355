import { MultiverseFile, WithMultiverseApiProps } from "../hoc/multiverseApiProvider";
import * as tus from 'tus-js-client'
import { getBlobUrl } from "../config/api";
import BGTaskManager, { TBackgroundTaskArgs } from "./backgroundTasks";
import api from "./api";

export type TUploadContextOpts = {
    uri: string;
    file: File;
    alwaysBackground?: boolean;
    onProgress?: (progress: number, status: string)=>void;
};


export type TUploadVideoContextOpts = {
    uri: string;
    file: File;
    upload_id?: string;
    transcode_jobname?: string,
    alwaysBackground?: boolean;
    onProgress?: (progress: number, status: string)=>void;
};

//main video upload body that fires off a tus upload and resolves promise when full upload is complete
const vimeoUpload = (opts: TUploadVideoContextOpts, upload_link: string, task_args: TBackgroundTaskArgs): Promise<void> => {
    const promise = new Promise<void>((resolve, reject) => {
        var upload = new tus.Upload(opts.file, {
            uploadUrl: upload_link,
            chunkSize: 16*1024*1024,
            onError: (error) => {
                reject(error);
            },
            onSuccess: () => {
                resolve();
            },
            onProgress: (bytesSent: number, bytesTotal: number) => {
                //notify both task and caller of progress
                const progress = bytesSent / bytesTotal;
                if(task_args.onProgress) {
                    task_args.onProgress(progress, "uploading")
                }
                if(opts.onProgress) {
                    opts.onProgress(progress, "uploading")
                }
            }
        })
        upload.start()
    })
    return promise;
}

//check status of the video upload
const videoCheckStatus = async(opts: TUploadVideoContextOpts) => {
    return (await api.post<any>({
        url: `/v2/${opts.uri}/content/videoupload/status`,
        body: {
            upload_id: opts.upload_id,
            transcode_jobname: opts.transcode_jobname
        }
    }));
}

//calls check status with initial delay of 10s
const videoWaitAndCheckStatus = (opts: TUploadVideoContextOpts): Promise<any> => {
    const promise = new Promise<void>((resolve, reject) => {
        const to = setTimeout(() => {
            clearTimeout(to);
            videoCheckStatus(opts)
            .then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err);
            })
        }, 10000)
    })
    return promise;
}

// retrieves width and height of video file

//main video upload body, wraps the firing off of a background task that does the full video
//upload process and then resolves the promise with the resulting stream url
// status can be:
// uploading, pending, transcoding, failed, complete
function videoUpload(opts: TUploadVideoContextOpts): Promise<MultiverseFile> {
    const { uri, file } = opts;
    if(opts.onProgress) {
        opts.onProgress(0.1, "pending");
    }
    return new Promise<MultiverseFile>((resolve,reject) => {
        BGTaskManager.addBackgroundTask({
            name: file.name,
            task: async(args: TBackgroundTaskArgs) => {
                try {

                    // prepare video upload
                    let body = {
                        name: file.name,
                        size: file.size,
                        lastModified: file.lastModified,
                        mimetype: file.type,
                    }

                    //start with video upload begin, which may immediately return streamurl for existing video,
                    //or may return resumable gcloud storage link for the upload
                    const begin_ul_res = await api.post<any>({
                        url: `/v2/${uri}/content/videoupload/begin`,
                        body: body
                    })

                    //check for streamurl, and if not found, begin upload
                    let entity: MultiverseFile | null = begin_ul_res.entity;
                    // if we have the entity, we can skip the upload as it already exists
                    if(entity) {
                        if(args.onProgress) {
                            args.onProgress(1.0, "complete")
                        }
                        if(opts.onProgress) {
                            opts.onProgress(1.0, "complete");
                        }
                        reject("Video already uploaded!");
                    } else {
                        // check for initial error, likely uploads not allowed on plan etc.
                        if(begin_ul_res.error) {
                            reject(begin_ul_res.error);
                        }
                    }
                    if(!entity && begin_ul_res.upload) {
                        const vidopts = {
                            ...opts,
                            upload_id: begin_ul_res.upload.upload_id,
                        }

                        let progress = 0.1;

                        //notify task+caller of progress
                        if(args.onProgress) {
                            args.onProgress(progress, "uploading")
                        }
                        if(opts.onProgress) {
                            opts.onProgress(progress, "uploading");
                        }

                        //do the main video upload, in chunks
                        const upload_link = begin_ul_res.upload.upload_uri;

                        // Step 2: Split the file into chunks and upload them
                        const chunkSize = 5 * 1024 * 1024; // 5MB
                        const totalChunks = Math.ceil(file.size / chunkSize);

                        for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
                            const start = chunkIndex * chunkSize;
                            const end = Math.min(start + chunkSize, file.size);
                            const chunk = file.slice(start, end);

                            await api.put<any>({
                                url: `/v2/${uri}/content/videoupload/uploadchunk/${upload_link}`,
                                headers: {
                                    'Content-Range': `bytes ${start}-${end - 1}/${file.size}`,
                                    'Content-Type': file.type,
                                },
                                body: chunk
                            })

                            console.log(`Uploaded chunk ${chunkIndex + 1}/${totalChunks}`);
                            progress = 0.1 + 0.65 * ((chunkIndex + 1) / totalChunks);
                            if(args.onProgress) {
                                args.onProgress(progress, "uploading")
                            }
                            if(opts.onProgress) {
                                opts.onProgress(progress, "uploading");
                            }
                        }

                        // Step 3: Finished the video file upload, so start processing and the transcoding job
                        const upload_res = await api.post<any>({
                            url: `/v2/${uri}/content/videoupload/process/${vidopts.upload_id}`,
                        })

                        vidopts.transcode_jobname = upload_res.transcode_jobname
                        if(args.onProgress) {
                            args.onProgress(0.8, upload_res.status)
                        }
                        if(opts.onProgress) {
                            opts.onProgress(0.8, upload_res.status);
                        }
                        if(upload_res.status === "failed") {
                            reject(upload_res.error);
                        }

                        //initial check of status to get initial state
                        let status_res = await videoCheckStatus(vidopts);
                        if(args.onProgress) {
                            args.onProgress(0.85, status_res.status)
                        }
                        if(opts.onProgress) {
                            opts.onProgress(0.85, status_res.status);
                        }
                        if(status_res.status === "failed") {
                            reject(status_res.error);
                        }

                        //loop with waiting checks of status to wait for transcoding to complete
                        while(true) {
                            status_res = await videoWaitAndCheckStatus(vidopts);
                            if(args.onProgress) {
                                args.onProgress(0.9, status_res.status)
                            }
                            if(opts.onProgress) {
                                opts.onProgress(0.9, status_res.status);
                            }
                            if(status_res.status === "complete") {
                                break;
                            }
                            if(status_res.status === "failed") {
                                break;
                            }
                        }
                        if(args.onProgress) {
                            args.onProgress(1.0, status_res.status)
                        }
                        if(opts.onProgress) {
                            opts.onProgress(1.0, status_res.status);
                        }

                        //finish the upload, which should return the streamurl
                        if(status_res.status === "complete") {
                            const end_ul_res = await api.post<any>({
                                url: `/v2/${uri}/content/videoupload/end`,
                                body: {
                                    upload_id: vidopts.upload_id,
                                    transcode_jobname: vidopts.transcode_jobname
                                }
                            })
                            entity = end_ul_res; //the result is a file entity with the 'link' property set
                        } else {
                            reject(status_res.error);
                        }
                    }
                    if(!entity) {
                        //throw new Error("Video upload failed");
                        reject("Video upload failed");
                    }
                    else
                        resolve(entity);

                } catch (err) {
                    reject(err);
                }
            } 
        })
    })
}

async function fileUploadTask(opts: TUploadContextOpts): Promise<MultiverseFile> {
    const { uri, file } = opts;
    const res = await api.post<MultiverseFile>({
        url: `/v2/${uri}/content?name=${file.name}`,
        body: file,
        hideErrors: true,
        headers: {
            'Content-Type': file.type,
            'x-shapevr-name': file.name,
        }
    });
    if(!res.blob) {
        throw new Error("File upload failed");
    }
    return res;
}

function fileUpload(opts: TUploadContextOpts) : Promise<MultiverseFile> {
    const { file } = opts;
    if(!opts.alwaysBackground && opts.file.type.startsWith("image/")) {
        return fileUploadTask(opts);
    } else {
        return new Promise<MultiverseFile>((resolve,reject) => {
            BGTaskManager.addBackgroundTask({
                name: file.name,
                task: async(args: TBackgroundTaskArgs) => {
                    try {
                        if(args.onProgress) {
                            args.onProgress(100,"transcoding");
                        }
                        if(opts.onProgress) {
                            opts.onProgress(100,"transcoding");
                        }
                        resolve(await fileUploadTask(opts));
                    } catch(err) {
                        reject(err);
                    }
                }
            })   
        })
    }
}

export async function uploadContent(opts: TUploadContextOpts): Promise<MultiverseFile> {
    const { file } = opts;
    console.log(`uploadContent: ${file.name} - type: ${file.type}`);
    if(file.type.startsWith("video/")) {
        return await videoUpload(opts);
    } else {
        return await fileUpload(opts);
    }
}