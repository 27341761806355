import { openDB, deleteDB, wrap, unwrap } from 'idb';


type TCacheEntry = {
    cache: string,
    name: string,
    expire: number,
    data: any
}

//simple storage mechanism in local storage.
export class LocalStorageCache {

    name: string;

    constructor(name: string) {
        this.name = name;
    }

    load = <T=any>(name: string): T | null => {
        const cache_name = `${this.name}.${name}`;
        const txt = window.localStorage.getItem(cache_name);
        if(txt) {
            const cache_object = JSON.parse(txt) as TCacheEntry;
            if(cache_object.expire > Date.now()) {
                return cache_object.data;
            } else {
                localStorage.removeItem(cache_name);
                return null;
            }
        } else {
            return null
        }
    }
    
    store = <T=any>(name: string, data: T, expire_milliseconds?: number) => {
        const cache_object: TCacheEntry = {
            cache: this.name,
            name: name,
            expire: Date.now()+(expire_milliseconds || 60*60*1000),
            data
        }
        // handle errors in local storage cache, othwerwise it will break the drives view and show nothing
        try {
            window.localStorage.setItem(`${this.name}.${name}`, JSON.stringify(cache_object));
        } catch(e) {
            console.error(e);
        }
    }
}

/*
export class IndexedDBCache {
    name: string;

    constructor(name: string) {
        this.name = name;
    }

    _init = async() => {
        const db = await openDB(`mvdb_${this.name}`, 1, {
            upgrade(db, oldVersion, newVersion, transaction) {
                const store = db.createObjectStore("cache")
                // …
            },
            blocked() {
            // …
            },
            blocking() {
            // …
            },
            terminated() {
            // …
            }
        });
    }

    load = async<T=any>(name: string): Promise<T | null> => {
        const cache_name = `${this.name}.${name}`;
        const txt = window.localStorage.getItem(cache_name);
        if(txt) {
            const cache_object = JSON.parse(txt) as TCacheEntry;
            if(cache_object.expire > Date.now()) {
                return cache_object.data;
            } else {
                localStorage.removeItem(cache_name);
                return null;
            }
        } else {
            return null
        }
    }
    
    store = async<T=any>(name: string, data: T, expire_milliseconds?: number) => {
        const cache_object: TCacheEntry = {
            cache: this.name,
            name: name,
            expire: Date.now()+(expire_milliseconds || 60*60*1000),
            data
        }
        window.localStorage.setItem(`${this.name}.${name}`, JSON.stringify(cache_object));
    }
}*/

//
export const Cache = LocalStorageCache;